import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Modal from 'antd/lib/modal/Modal';
import PaymentImage from '../../images/payment.png';
import { Space, Descriptions, Button, Row, Col } from 'antd';
import { AMOUNT, AMOUNTCH, TVACH } from '../../utils/constants';

interface IProps {
    open: boolean;
    isSwiss: boolean;
    onCancel: () => void;
    onClickOnlinePay: () => void;
    onClickBankTransferPayed: () => void;
}

type Props = IProps & WrappedComponentProps;

interface State {
    buttonDisabled: boolean,
}

class PaymentModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            buttonDisabled: false,
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <Modal
                visible={this.props.open}
                title={formatMessage({ id: 'registration.modal.title' })}
                onCancel={this.props.onCancel}
                footer={null}
                maskClosable={false}
                width={'600px'}>
                <Space size={20} direction="vertical" style={{ width: '100%' }}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <p><span style={{ fontSize: "180%", fontWeight:"bold" }} >CHF {this.props.isSwiss ? (Math.round(AMOUNTCH * 100) / 100).toFixed(2) : (Math.round(AMOUNT * 100) / 100).toFixed(2)}</span><span style={{ marginLeft: '20px' }}>CHF {this.props.isSwiss ? `${(Math.round(AMOUNTCH * 100) / 100).toFixed(2)} (${(Math.round((AMOUNTCH - TVACH) * 100) / 100).toFixed(2)} + ${(Math.round(TVACH * 100) / 100).toFixed(2)} TVA)` : `${(Math.round(AMOUNT * 100) / 100).toFixed(2)} (${(Math.round(AMOUNT * 100) / 100).toFixed(2)} + 0.- TVA)`}</span></p>
                        </Col>
                    </Row>
                    <div className="registration-modal-choice-parent">
                        <div>
                            <Descriptions title={formatMessage({ id: 'registration.modal.online' })} column={1} />
                            <img alt="Payment" src={PaymentImage} className="registration-modal-payment-image" />
                        </div>
                        <Button
                            style={{marginTop:"10px"}}
                            type="primary"
                            onClick={() => { this.setState({ buttonDisabled: true }); this.props.onClickOnlinePay() }}
                            disabled={this.state.buttonDisabled}>
                            {formatMessage({ id: 'registration.modal.pay' })}
                        </Button>
                    </div>
                    <div className="registration-modal-choice-parent">
                        <Descriptions title={formatMessage({ id: 'registration.modal.bankTransfer' })} column={1}>
                            <Descriptions.Item label={formatMessage({ id: 'registration.modal.beneficiary' })}>{'Terraxis SA, CHE-232.541.005'}</Descriptions.Item>
                            <Descriptions.Item>{"Rue de la Tour-de-l'Ile 1, 1204 Genève"}</Descriptions.Item>
                            <Descriptions.Item label={"IBAN"}>{'CH 49 0024 0240 8959 7901 D'}</Descriptions.Item>
                            <Descriptions.Item label={formatMessage({ id: 'registration.modal.account' })}>{'240 -895979.01D'}</Descriptions.Item>
                            <Descriptions.Item label={"BIC"}>{'UBSWCHZH80A'}</Descriptions.Item>
                            <Descriptions.Item label={formatMessage({ id: 'registration.modal.note' })}>{formatMessage({ id: 'registration.modal.note.content' })}</Descriptions.Item>
                        </Descriptions>
                        <Button
                            type="primary"
                            onClick={() => { this.setState({ buttonDisabled: true }); this.props.onClickBankTransferPayed() }}
                            disabled={this.state.buttonDisabled}>
                            {formatMessage({ id: 'registration.modal.payed' })}
                        </Button>
                    </div>
                </Space>
            </Modal>
        )
    }
}

export default injectIntl(PaymentModal);