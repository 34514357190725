import React, { ReactNode } from 'react'
import { Layout, Col, Row } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import LogoImage from '../../images/terraxis_logo.png';
import moment from 'moment';

interface IProps { 
    children: ReactNode;
}

type Props = IProps & WrappedComponentProps;

interface State { }

class Container extends React.Component<Props, State> {
    render() {
        const { Header, Content, Footer } = Layout;
        return (
            <Layout style={{ minHeight: "100vh" }} className="layout">
                <Header>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={2} xl={2} xxl={2} style={{ textAlign: "center" }}>
                            <a href="https://terraxis.ch" className="uk-navbar-item uk-logo">
                                <img alt="Terraxis" src={LogoImage} width="137" height="40" />
                            </a>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    {this.props.children}
                </Content>
                <Footer >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6} xxl={4} style={{ textAlign: "center" }}>Copyright {moment().year()} Terraxis SA</Col>
                        <Col xs={0} sm={0} md={0} lg={1} xl={1} style={{ textAlign: "center" }}>|</Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6} xxl={4} style={{ textAlign: "center" }}>Création WebEvolutions</Col>
                    </Row>
                </Footer>
            </Layout>
        )
    }
}



export default injectIntl(Container);