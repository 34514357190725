import React from 'react';
import { Form, Input, Button } from 'antd';
import Logo from '../../../images/we_logo_transparent.png';
import { BackofficeLoginBodyRequest, RouterProps } from '../../../utils/types';
import Network from '../../../utils/network';
import { Store } from 'antd/lib/form/interface';
import { displayErrorMessage } from '../../../utils/utils';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter } from '../../../utils/withRouter';

type Props = RouterProps & WrappedComponentProps;

interface State {
    loading: boolean;
}

/**
 * Component that represent the login page
 */
class Login extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        document.title = "WebEvolutions - Admin - Login";

        
    }

    onSubmit = (values: Store) => {
        this.setState({ loading: true });
        Network.login(values as BackofficeLoginBodyRequest)
            .then(() => {
                this.setState({ loading: false });
                this.redirectToAdministration();
            })
            .catch(() => {
                this.setState({ loading: false });
                displayErrorMessage(this.props.intl.formatMessage({ id: "login.error" }), "login-button");
            });
    }

    redirectToAdministration = () => this.props.router.navigate(`/${this.props.router.params.lang}/backoffice/administration`);

    render() {
        console.log('Test22')
        const { formatMessage } = this.props.intl;
        return (
            <div className="login-parent">
                <div className="login-background-image" />
                <div className="login-background">
                    <div className="login-content">
                        <div className="login-header">
                            <img alt="Login logo" className="login-logo" src={Logo} />
                        </div>
                        <Form
                            labelAlign="left"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            onFinish={this.onSubmit}>
                            <Form.Item
                                className="login-form-item"
                                label={formatMessage({ id: "login.email" })}
                                name="email"
                                rules={[{ required: true, message: formatMessage({ id: "login.enterEmail" }) }]}>
                                <Input
                                    className="login-input"
                                    placeholder={formatMessage({ id: "login.email" })} />
                            </Form.Item>
                            <Form.Item
                                className="login-form-item"
                                label={formatMessage({ id: "login.password" })}
                                name="password"
                                rules={[{ required: true, message: formatMessage({ id: "login.enterPassword" }) }]}>
                                <Input.Password
                                    className="login-input"
                                    placeholder={formatMessage({ id: "login.password" })} />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{ span: 4, offset: 10 }}>
                                <Button
                                    id="login-button"
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.loading}>
                                    {formatMessage({ id: "login.submit" })}
                            </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(Login));