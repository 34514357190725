import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'antd/dist/antd.less';
import './css/variables.css';
import './css/animations.css';
import './App.css';
import './css/antd-modifications.css';
import './css/registration.css';
import './css/backoffice-login.css';
import './css/backoffice-container.css';
import './css/backoffice-administration.css';
import Root from './components/root/root';


class App extends React.Component<{}, {}>{
  render() {
    return (
      <Router>
        <Root />
      </Router>
    );
  }
}


export default App;
