import React from 'react';
import { Table } from 'antd';
import { withRouter } from '../../utils/withRouter';

type Props = {
    label: string;
    text: string;
    onTextChange: (text: string) => void;
};

let columns: import("antd/lib/table").ColumnsType<any> | undefined = []
let data: any[] | undefined = [];

class Administration extends React.Component {
    constructor(props: Props) {
        super(props);
        for (let i = 0; i < 100; i++) {
            data?.push({
                key: i,
                name: `Edrward ${i}`,
                age: 32,
                address: `London Park no. ${i}`,
            });
        }
        columns = [
            {
                title: 'Nom de la société',
                width: 100,
                dataIndex: 'name',
                key: 'name',
                fixed: 'left', 
                onFilter: (value, record) => record.name.indexOf(value) === 0,
                sorter: (a, b) => a.name.length - b.name.length,
                sortDirections: ['descend'],
            },
            {
                title: 'Adresse',
                width: 100,
                dataIndex: 'age',
                key: 'age',
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Code Postal',
                dataIndex: 'address',
                key: '1',
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Ville',
                dataIndex: 'address',
                key: '2',
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Pays',
                dataIndex: 'address',
                key: '3',
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Canton',
                dataIndex: 'address',
                key: '4',
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Nom',
                dataIndex: 'address',
                key: '5',
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Prènom',
                dataIndex: 'address',
                key: '6',
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Téléphone',
                dataIndex: 'address',
                key: '7',
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            { 
                title: 'Email', 
                dataIndex: 'address', 
                key: '8' ,
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            { 
                title: 'Organisme d\'affiliation actuel', 
                dataIndex: 'address', 
                key: '9' ,
                width: 150,
                sorter: (a, b) => a.amount - b.amount,
            },
            {
                title: 'Action',
                key: 'operation',
                fixed: 'right',
                width: 100,
                sorter: (a, b) => a.amount - b.amount,
                render: () => <a href="/#">action</a>,
            },
        ];
    }

    render() {
        return <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 300 }} />
    }

    componentDidMount() {
        // document.title = "Terraxis - Registration";
    }
}

export default withRouter(Administration);