import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouterProps } from '../../utils/types';
import '../../css/paysuccess.css';
import { withRouter } from '../../utils/withRouter';

interface IProps {
};

interface State {
    errorId: number,
}

type Props = IProps & WrappedComponentProps & RouterProps;

class Ibansuccess extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            errorId: 0
        }
    }

    componentDidMount() {
        // document.title = "Terraxis - Ibansuccess";
    }

    renderSwitch() {
        const { formatMessage } = this.props.intl;
        switch (this.state.errorId) {
            case 0:
                return (
                    <React.Fragment>
                        <Typography>
                        <div  dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'ibansuccess.success' }) }} />
                        </Typography>
                    </React.Fragment>
                );
            case 1:
                return (
                    <React.Fragment>
                        <Typography>{formatMessage({ id: 'ibansuccess.error' })}</Typography>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <Typography>{formatMessage({ id: 'paysuccess.loading' })}</Typography>
                    </React.Fragment>
                )
        }
    }

    render() {
        return (
            <Row>
                <Col className="success-div" span={18} offset={3}>
                    {this.renderSwitch()}
                </Col>
            </Row>
        );
    }
}

export default withRouter(injectIntl(Ibansuccess));