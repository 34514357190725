import React from 'react';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { PayyoCaptureBobyRequest, RouterProps } from '../../utils/types';
import Network from '../../utils/network';
import '../../css/paysuccess.css';
import moment from 'moment';
import { withRouter } from '../../utils/withRouter';

interface IProps {
};

interface State {
    errorId: number,
    customerId: number,
    customerCreated: string,
}

const PAYMENTDONE = "settled"
const PAYMENTERROR = "notSettled"

type Props = IProps & WrappedComponentProps & RouterProps;

class Paysuccess extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            errorId: -1,
            customerId: -1,
            customerCreated: "",
        }
        
    }

    componentDidMount() {
        const { formatMessage } = this.props.intl;
        const search = this.props.router.location.search;
        const params = new URLSearchParams(search);
        const transactionId = params.get('transaction_id');
        const company_id = localStorage.getItem('companyId') || 0;
        let lang = this.props.router.params.lang;

        if (company_id === 0 || transactionId === null || transactionId === "") window.location.href = "/registration";

        //const query = qs.parse(this.props.router.location.search, {ignoreQueryPrefix: true, comma: true})
        let infoValues: PayyoCaptureBobyRequest = {
            typeQuery: "capture",
            lang: lang,
            transactionId: transactionId as string,
            companyId: localStorage.getItem('companyId') || "",
            email: localStorage.getItem('email') || "",
            firstName: localStorage.getItem('firstName') || "",
            lastName: localStorage.getItem('lastName') || "",

            fieldsValues: {
                transaction_id: transactionId as string,
                city: localStorage.getItem('city') || "",
                company_address: localStorage.getItem('company_address') || "",
                company_name: localStorage.getItem('company_name') || "",
                country: localStorage.getItem('country') || "",
                current_affiliate: localStorage.getItem('current_affiliate') || "",
                phone: localStorage.getItem('phone') || "",
                postcode: localStorage.getItem('postcode') || "",
                canton: localStorage.getItem('canton') || "",
                product: localStorage.getItem('product') || "",
                
            }
        }
        // document.title = "Terraxis - Paysuccess";

        Network.payyoCapture(infoValues).then(data => {
            console.warn("TWINT-", data);
            if (!data.data["result"]) {
                this.setState({errorId:1});
            } else if (data.data["result"] === PAYMENTDONE) {
                let str:string;
                str = data.data["customerCreated"];
                let created = moment(str).format("YYYYMMDD") + "-";
                console.log("TEST", created)
                this.setState({customerId: data.data["customerId"], customerCreated:created, errorId:0})
                Network.getTerraxisCertificate("sendCertificate", formatMessage({ id: 'lang' }), data.data["customerId"]).then(data => console.log(data));
            } else if (data.data["result"] === PAYMENTERROR) {
                this.setState({errorId:2});
            } else {
                this.setState({errorId:3});
            }
        });


        localStorage.clear();
    }

    sendCertificat() {
        return "ok"
    }

    renderSwitch() {
        const { formatMessage } = this.props.intl;
        switch(this.state.errorId) {
            case 0:
                return (      
                    <React.Fragment>
                                
                    <Typography><span  dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.settled' }) }} /></Typography>
                    {/* <Typography>{formatMessage({ id: 'paysuccess.transaction' })} {this.state.customerCreated + this.state.customerId}</Typography> */}
                    </React.Fragment>
                )
            case 1:
                return  (      
                    <React.Fragment> 
                                
                    <Typography><span  dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} /> </Typography>
                    </React.Fragment>
                )
            case 2:
                return (      
                    <React.Fragment> 
                    <span  dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} />             
                    <Typography><span  dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} /> </Typography>
                    </React.Fragment>
                )
            case 3:
                return (      
                    <React.Fragment>    
                    <span  dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} />          
                    <Typography><span  dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'paysuccess.notsettled' }) }} /> </Typography>
                    </React.Fragment>
                );
          default:
            return (      
                <React.Fragment>              
                <Typography>{formatMessage({ id: 'paysuccess.loading' })}</Typography>
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <Row>
                <Col className="success-div" span={18} offset={3}>                        
                    {this.renderSwitch()}
                </Col>
            </Row>
        );
    }
}

export default withRouter(injectIntl(Paysuccess));