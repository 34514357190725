import moment from 'moment';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import LanguageProvider from '../providers/languageProvider';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import enGB from 'antd/lib/locale/en_GB';
import BackofficeContainer from '../backoffice/common/container';
import Container from '../common/container';
import React from 'react';


export const Layout = () => {
    const location = useLocation();
    const { lang } = useParams();
    const url = location.pathname.split("/");
    if (url.length > 2 && url[2] !== undefined && url[2] !== '' && lang !== undefined && ["en", "fr"].includes(lang)) {
        return <Outlet />;
    } else {
        let locale = 'fr';
        if (lang !== undefined && ["en", "fr"].includes(lang)) {
            locale = lang;
        }
        return <Navigate to={`/${locale}/registration`} replace />;
    }
};
export default Layout;

export const LocaleLayout = () => {
    const location = useLocation();
    const { lang } = useParams();

    const url = location.pathname.split("/");
    if (url.length > 2 && url[2] !== undefined && url[2] !== '' && lang !== undefined && ["en", "fr"].includes(lang)) {
        moment.locale(lang);
        let antdLang=frFR
        if (lang === 'en'){
            antdLang=enGB
        }
        return <LanguageProvider><ConfigProvider locale={antdLang}><Outlet /></ConfigProvider></LanguageProvider>;

    } else {
        let locale = 'fr';
        if (lang !== undefined && ["en", "fr"].includes(lang)) {
            locale = lang;
        }
        return <Navigate to={`/${locale}/registration`} replace />;


    }
};
export const FrontLayout = () => {
    return <div id={'frontoffice-root'}><Container><Outlet /></Container></div>;

};
export const BackOfficeLayout = () => {
    const location = useLocation();
    const { lang } = useParams();

    const url = location.pathname.split("/");

    if(url.length > 2 && url[2] != null && url[2] === 'backoffice'){
        if(url.length > 3 && url[3] != null && url[3] !== ''){
            return <div id={'backoffice-root'}><Outlet /></div>;
        }else{
            return <Navigate to={`/${lang}/backoffice/login`} replace />;
        }
    }else{
        return <Navigate to={`/${lang}/registration`} replace />;

    }

};
export const AuthBackOfficeLayout = () => {
    const { lang } = useParams();

    const token = localStorage.getItem("token");

    if (token) {
        return <BackofficeContainer><Outlet /></BackofficeContainer>;

    } else {
        return <Navigate to={`/${lang}/backoffice/login`} replace />;
    }
}

export const UnAuthBackOfficeLayout = () => {
    const { lang } = useParams();

    const token = localStorage.getItem("token");

    if (token) {
        return <Navigate to={`/${lang}/backoffice/administration`} replace />;


    } else {
        return <Outlet />;
    }
}

export const NotFoundLayout = () => {
    const { lang } = useParams();

    let locale = 'fr';
    if (lang !== undefined && ["en", "fr", "de", "it"].includes(lang)) {
        locale = lang;
    }

        return <Navigate to={`/${locale}/login`} replace />;

};